// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/global.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/proposal.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n.amount-input[data-v-7958917d] {\n  width: 100px;\n}\n/**** DropDown **** */\n.header__dropdown[data-v-7958917d] {\n  display: flex;\n  margin: 5px 10px;\n  color: rgb(173, 181, 189);\n}\n.header__dropdown > img[data-v-7958917d] {\n  padding-right: 6px;\n}\n.item__dropdown[data-v-7958917d] {\n  padding: 0 0.5rem 0 1rem;\n  font-size: 0.9rem;\n}\n\n/**table */\n.table-package-medicine[data-v-7958917d] .headerClass {\n  color: var(--gray-color);\n  border: none;\n}\n\n/** button price table */\n.country-group[data-v-7958917d] {\n  padding: 20px 20px 20px 50px;\n}\n.card-country[data-v-7958917d] {\n  height: 60px;\n  min-width: 220px;\n  display: flex;\n  flex-direction: row;\n  margin: 0.5rem;\n}\n.align-end[data-v-7958917d] {\n  text-align: end;\n}\n", ""]);
// Exports
module.exports = exports;
