<template>
  <div>
    <b-card-group deck>
      <b-card :header="this.$i18n.t('proposal.Medicine.titlePackage')" class="text-center tableMedicine">
        <b-card-text class="mt-3 ml-4 tableMedicineItem">
          <div class="text-center" v-if="loadingPackage">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
          <!-- PACKAGE table element -->

          <h6 class="border-bottom">{{ item.nombre_paquete }}</h6>

          <b-table
            :items="item.componentes"
            :fields="fields"
            class="mt-4 table-package-medicine"
            thead-tr-class="headerClass"
            sort-icon-left
            :busy="isBusy"
          >
            <template #cell(CodigoAlterno)="data">
              <b>{{ data.value }}</b>
            </template>
            <template #cell(restantes)="data">
              <b :class="{ 'text-danger': data.value === 0 }">{{ data.value }}</b>
            </template>

            <template #cell(inputAmount)="data">
              <b-form-input
                type="number"
                min="1"
                class="amount-input"
                :disabled="!data.item.stock"
                :state="validInput(data.item)"
                v-model="amountInput[data.item.Articulo]"
                @change="handleAmount(data.item.Articulo, data.item.stock)"
              ></b-form-input>
            </template>
            <template #cell(stock)="data">
              <b :class="{ 'text-danger': data.value === 0 }">{{ data.value }}</b>
              <b-spinner class="align-middle" v-if="loadingStock"></b-spinner>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-card-text>
      </b-card>
    </b-card-group>
    <div class="text-right">
      <b-button
        variant="outline-primary"
        class="btn-blue-dark ml-0"
        @click="handleConfirmMedicine"
        :disabled="pharmacySelected.value ? false : true"
      >
        <span>{{ $t('medicine.confirmButton') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { API_ROOT } from '../../Constants/Api';
import { NumberFormat } from '../../helpers/NumberFormat';

import { AlertConfirm, AlertConfirmThree, AlertForm, AlertFormLarge, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: 'PackageMedicineConfig',
  data() {
    return {
      country: '',
      //table package
      item: [],
      packageSelected: [],
      isActive: null,
      message: 'No information',
      loadingPackage: false,
      amountInput: {},
      infoMedicines: null,
      isBusy: false,
      loadingStock: false,
      typeUser: null,
    };
  },

  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.typeUser = this.$store.state.typeUser;
    this.nhc = sessionStorage.getItem('nhc');
    this.infoMedicines = JSON.parse(sessionStorage.getItem('arrayMedicine') || '[]');
    if (this.infoMedicines.length) {
      this.infoMedicines.forEach((el) => {
        this.amountInput[el.value] = el.amount.toString();
      });
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'CodigoAlterno',
          label: this.$i18n.t('medicine.table.medicine'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'incluye',
          label: this.$i18n.t('medicine.table.includes'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'entregados',
          label: this.$i18n.t('medicine.table.delivered'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'restantes',
          label: this.$i18n.t('medicine.table.remaining'),
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'inputAmount', label: this.$i18n.t('medicine.table.amount') },
        { key: 'stock', label: 'Stock' },
        /* { key: "actions", label: "Action" }, */
      ];
    },
    pharmacySelected() {
      return this.$store.state.pharmacy_selected;
    },
    branchSelected() {
      return this.$store.state.sede_origen;
    },
  },

  watch: {
    pharmacySelected() {
      if (this.pharmacySelected.value) {
        this.stockInPharmacy();
      }
    },
    async branchSelected() {
      await this.updateTable();
    },
  },
  methods: {
    async updateTable() {
      this.isBusy = true;
      try {
        const data = await ApiRequest.post(`${API_ROOT}control-medicamentos`, {
          email: sessionStorage.getItem('email'),
          localidad: '1',
        });
        let updateComponents = data.componentes.map((el) => {
          return {
            ...el,
            restantes: el.incluye - el.entregados,
          };
        });
        this.item = { ...data, componentes: updateComponents };
      } catch (err) {
        return AlertForm(`Error ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isBusy = false;
      }
    },
    handleAmount(idProd, stock) {
      let numberAmount = Number(this.amountInput[idProd]);
      if (numberAmount < 1) {
        this.amountInput[idProd] = '';
      } else if (numberAmount > stock) {
        this.amountInput[idProd] = stock;
      }
    },
    handleConfirmMedicine() {
      let arrayConfirmMedicine = [],
        arraySurplusMedicine = [];

      for (const el in this.amountInput) {
        if (this.amountInput[el]) {
          let findMedicine = this.item.componentes.find((medicine) => medicine.Articulo === el);
          if (this.amountInput[el] <= findMedicine.restantes) {
            arrayConfirmMedicine.push({ ...findMedicine, inputAmount: Number(this.amountInput[el]) });
          } else {
            if (findMedicine.restantes)
              arrayConfirmMedicine.push({ ...findMedicine, inputAmount: findMedicine.restantes });
            arraySurplusMedicine.push({
              ...findMedicine,
              inputAmount: Number(this.amountInput[el]) - findMedicine.restantes,
            });
          }
        }
      }

      if (!arrayConfirmMedicine.length && !arraySurplusMedicine.length)
        return AlertForm(this.$i18n.t('medicine.selectMedicine'), '', ICON.INFO, 'center');
      if (arraySurplusMedicine.length) {
        let text = this.$i18n.t('medicine.textAlert');
        AlertConfirmThree(
          this.$i18n.t('medicine.warning'),
          text,
          () => this.correctMedicine(arrayConfirmMedicine),
          () => this.createBussiness(arrayConfirmMedicine, arraySurplusMedicine),
          this.$i18n.t('medicine.correct'),
          this.$i18n.t('medicine.CreateBusiness')
        );
      } else {
        AlertConfirm(
          this.$i18n.t('medicine.confirmButton'),
          this.$i18n.t('medicine.continue'),
          ICON.WARNING,
          () => this.businessMedicine(arrayConfirmMedicine),
          'Ok'
        );
      }
    },
    async correctMedicine(arrayConfirmMedicine) {
      try {
        if (!arrayConfirmMedicine.length)
          return AlertForm(this.$i18n.t('medicine.correctMedicine'), '', ICON.INFO, 'center');
        await this.businessMedicine(arrayConfirmMedicine);
        await this.updateTable();
        await this.stockInPharmacy();
        this.clearFields();
      } catch (error) {
        console.log(error);
      }
    },
    async createBussiness(arrayConfirmMedicine, arraySurplusMedicine) {
      if (arrayConfirmMedicine.length) {
        await this.businessMedicine(arrayConfirmMedicine);
        AlertFormLarge(this.$i18n.t('medicine.msg1'), '', ICON.SUCCESS);
      } else {
        AlertFormLarge(this.$i18n.t('medicine.msg2'), '', ICON.INFO);
      }
      let surplusMedicine = arraySurplusMedicine.map((el) => {
        return {
          amount: el.inputAmount,
          currencyFormat: el.monedaPrecio,
          data: el,
          description: el.CodigoAlterno,
          name: 'MEDICINA INDIVIDUAL',
          price: el.PrecioEspecial,
          value: el.Articulo,
        };
      });
      sessionStorage.setItem('arrayMedicine', JSON.stringify(surplusMedicine));
      this.$router.push({ name: 'Recipe', params: { name: 'medicamento' } });
    },
    async businessMedicine(arrayConfirmMedicine) {
      try {
        let filterMedicine = arrayConfirmMedicine;

        // logica de negocio en medicina

        let dataGeneral = filterMedicine.map((el) => {
          return {
            Articulo: el.Articulo,
            CodigoAlterno: el.CodigoAlterno,
            Categoria: 'MEDICINA INDIVIDUAL',
            Familia: '',
            Unidad: '',
            PrecioLista: this.typeUser === 'Donante' ? 0 : el.precioLista,
            detalleDescuento: {
              PrecioLista: this.typeUser === 'Donante' ? 0 : el.precioLista,
              PrecioEspecial: this.typeUser === 'Donante' ? 0 : el.PrecioEspecial,
              entregaMedicamento: true,
            },
            Estatus: '',
            monedaPrecio: el.monedaPrecio,
            PorcentajeMexico: el.PorcentajeMexico,
            PorcentajeUS: el.PorcentajeUS,
            SSPTAMX: '',
            SSPTAUS: '',
            createdAt: '',
            updatedAt: '',
            __v: 0,
            Cantidad: el.inputAmount.toString(),
            Relacionados: [],
          };
        });
        const body = {
          vid: sessionStorage.getItem('vid'),
          owner_id: sessionStorage.getItem('ownerId'),
          sede_origen: JSON.parse(sessionStorage.getItem('sedeOrigen')).name,
          sede_origen_key: JSON.parse(sessionStorage.getItem('sedeOrigen')).value,
          sede_destino: JSON.parse(sessionStorage.getItem('sedeDestino')).value,
          firstname: sessionStorage.getItem('nombre'),
          lastname: sessionStorage.getItem('apellido'),
          nhc: this.nhc,
          pais: this.country, // MX - US
          producto: 'MEDICAMENTOS',
          data: dataGeneral,
          user_comision: this.$store.state.username || sessionStorage.getItem('userName'),
        };
        await ApiRequest.post(`${API_ROOT}cotizacion/cotiza-indv-pln`, body);
        AlertForm(this.$i18n.t('medicine.success'), this.$i18n.t('medicine.msgSuccess'), ICON.SUCCESS, 'center');
        await this.updateTable();
        await this.stockInPharmacy();
        this.clearFields();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('permissions.errorMedicine'), ICON.ERROR);
      }
    },
    clearFields() {
      this.amountInput = {};
    },
    async stockInPharmacy() {
      this.loadingStock = true;
      try {
        const data = await ApiRequest.post(
          `${API_ROOT}filtrosMedicamentos/medicamentosIndividuales`,
          this.pharmacySelected.value
        );

        let copyData = JSON.parse(JSON.stringify(this.item));
        copyData.componentes.forEach((medicine) => {
          let objMedicine = data.data.find((el) => el.Articulo === medicine.Articulo);
          medicine.stock = objMedicine ? objMedicine.Disponible || 0 : 0;
        });
        this.item = copyData;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        await this.updateTable();
      } finally {
        this.loadingStock = false;
      }
    },

    validInput(item) {
      if (this.amountInput[item.Articulo] < 0) return false;
      return this.amountInput[item.Articulo] ? (this.amountInput[item.Articulo] || 0) <= item.restantes : null;
    },
    format(number) {
      return NumberFormat(number);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.isActive) return 'table-row';
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

.amount-input {
  width: 100px;
}
/**** DropDown **** */
.header__dropdown {
  display: flex;
  margin: 5px 10px;
  color: rgb(173, 181, 189);
}
.header__dropdown > img {
  padding-right: 6px;
}
.item__dropdown {
  padding: 0 0.5rem 0 1rem;
  font-size: 0.9rem;
}

/**table */
.table-package-medicine /deep/ .headerClass {
  color: var(--gray-color);
  border: none;
}

/** button price table */
.country-group {
  padding: 20px 20px 20px 50px;
}

.card-country {
  height: 60px;
  min-width: 220px;
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
}

.align-end {
  text-align: end;
}
</style>
