var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container__medicine" },
    [
      _c(
        "b-container",
        [
          _c("Owner"),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c("NavbarInfo"),
                      _c("b-col", [
                        _c(
                          "div",
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { md: "8" } }, [
                                  _c("h3", { staticClass: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("medicine.title")) +
                                        " "
                                    )
                                  ]),
                                  _c("span", { staticClass: "small-title" }, [
                                    _vm._v(_vm._s(_vm.$t("medicine.Fertility")))
                                  ])
                                ]),
                                _c(
                                  "b-col",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "menu__link",
                                        attrs: { to: "/proposal" }
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "btn-blue-dark-outline ml-0",
                                            attrs: {
                                              variant: "outline-primary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("recipe.Button")
                                                ) +
                                                " + "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-5 proposal__page" },
                              [
                                _c(
                                  "b-row",
                                  { attrs: { "align-v": "start" } },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "p-0",
                                        attrs: { cols: "6" }
                                      },
                                      [_c("SelectPharmacy")],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [_c("PackageMedicineConfig")],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }