<template>
  <div class="container__medicine">
    <b-container>
      <Owner />
      <b-row align-v="center">
        <b-col>
          <b-row>
            <NavbarInfo />
            <b-col>
              <div>
                <b-row>
                  <b-col md="8">
                    <h3 class="title">
                      {{ $t('medicine.title') }}
                    </h3>
                    <span class="small-title">{{ $t('medicine.Fertility') }}</span>
                  </b-col>
                  <b-col class="text-right">
                    <router-link class="menu__link" to="/proposal">
                      <b-button variant="outline-primary" class="btn-blue-dark-outline ml-0">
                        {{ $t('recipe.Button') }} +
                      </b-button>
                    </router-link>
                  </b-col>
                </b-row>

                <div class="mt-5 proposal__page">
                  <b-row align-v="start">
                    <b-col cols="6" class="p-0">
                      <SelectPharmacy />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <PackageMedicineConfig />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Owner from '@/components/Owner';
import NavbarInfo from '@/components/NavbarInfo';
import SelectPharmacy from '@/components/Proposal/SelectPharmacy';
import PackageMedicineConfig from '@/components/Medicine/PackageMedicineConfig';
import { AlertForm, ICON } from '../helpers/Alert';

export default {
  name: 'Medicine',
  components: {
    NavbarInfo,
    Owner,
    SelectPharmacy,
    PackageMedicineConfig,
  },
  data() {
    return {
      country: '',
      fields: [
        { key: 'date', label: 'Date', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '' },
      ],
      items: [],
      fieldsIndividual: [],
      configRol: {},
    };
  },
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.medicina?.ventana === false) {
      this.$router.push({ name: 'Welcome' });
      AlertForm(
        `${this.$i18n.t('permissions.unauthorized')} ${this.$i18n.t('menu.Medicine')}`,
        this.$i18n.t('permissions.validateAdmin'),
        ICON.WARNING,
        'center',
        null
      );
    }
  },
  computed: {
    fieldsPackage() {
      return [
        {
          key: 'name',
          label: this.$i18n.t('medicine.Medicine'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'incluye',
          label: this.$i18n.t('medicine.Include'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'entregados',
          label: this.$i18n.t('medicine.Delivered'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'restantes',
          label: this.$i18n.t('medicine.Remaining'),
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'actions', label: this.$i18n.t('medicine.Detail') },
      ];
    },
    updateConfigRol() {
      return this.$store.state.configRol;
    },
  },

  watch: {
    updateConfigRol() {
      this.configRol = this.updateConfigRol;
      if (this.configRol.medicina?.ventana === false) {
        this.$router.push({ name: 'Welcome' });
        AlertForm(
          `${this.$i18n.t('permissions.unauthorized')} ${this.$i18n.t('menu.Medicine')}`,
          this.$i18n.t('permissions.validateAdmin'),
          ICON.WARNING,
          'center',
          null
        );
      }
    },
  },

  mounted() {
    this.country = sessionStorage.getItem('country');
  },

  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.isActive) return 'table-row-title-action';
    },
  },
};
</script>

<style scoped>
@import '../assets/css/global.css';

.container__medicine {
  margin-bottom: 3rem;
}
/** TITLES */
.title {
  text-align: left;
  margin: 30px 30px 10px 0px;
}

.small-title {
  font-size: 18px;
  color: #b9b9b9;
  margin-left: 12px;
}

.card-header {
  background: #fff;
  border-radius: 2rem 2rem 0 0;
  font-family: 'futura-heavy';
  padding: 20px 30px;
  text-align: left;
  color: #707070;
  font-size: 1.3rem;
}

.card-deck .card {
  margin: 10px 12px;
  border-radius: 14px;
}

.card-body {
  padding: 0px 14px;
  text-align: left;
}

.tableMedicineItem /deep/ .table-head-none {
  display: none;
}
</style>
