var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-group",
        { attrs: { deck: "" } },
        [
          _c(
            "b-card",
            {
              staticClass: "text-center tableMedicine",
              attrs: { header: this.$i18n.t("proposal.Medicine.titlePackage") }
            },
            [
              _c(
                "b-card-text",
                { staticClass: "mt-3 ml-4 tableMedicineItem" },
                [
                  _vm.loadingPackage
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-spinner", {
                            attrs: {
                              variant: "primary",
                              label: "Text Centered"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h6", { staticClass: "border-bottom" }, [
                    _vm._v(_vm._s(_vm.item.nombre_paquete))
                  ]),
                  _c("b-table", {
                    staticClass: "mt-4 table-package-medicine",
                    attrs: {
                      items: _vm.item.componentes,
                      fields: _vm.fields,
                      "thead-tr-class": "headerClass",
                      "sort-icon-left": "",
                      busy: _vm.isBusy
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(CodigoAlterno)",
                        fn: function(data) {
                          return [_c("b", [_vm._v(_vm._s(data.value))])]
                        }
                      },
                      {
                        key: "cell(restantes)",
                        fn: function(data) {
                          return [
                            _c(
                              "b",
                              { class: { "text-danger": data.value === 0 } },
                              [_vm._v(_vm._s(data.value))]
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(inputAmount)",
                        fn: function(data) {
                          return [
                            _c("b-form-input", {
                              staticClass: "amount-input",
                              attrs: {
                                type: "number",
                                min: "1",
                                disabled: !data.item.stock,
                                state: _vm.validInput(data.item)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleAmount(
                                    data.item.Articulo,
                                    data.item.stock
                                  )
                                }
                              },
                              model: {
                                value: _vm.amountInput[data.item.Articulo],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.amountInput,
                                    data.item.Articulo,
                                    $$v
                                  )
                                },
                                expression: "amountInput[data.item.Articulo]"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "cell(stock)",
                        fn: function(data) {
                          return [
                            _c(
                              "b",
                              { class: { "text-danger": data.value === 0 } },
                              [_vm._v(_vm._s(data.value))]
                            ),
                            _vm.loadingStock
                              ? _c("b-spinner", { staticClass: "align-middle" })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-primary my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-blue-dark ml-0",
              attrs: {
                variant: "outline-primary",
                disabled: _vm.pharmacySelected.value ? false : true
              },
              on: { click: _vm.handleConfirmMedicine }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("medicine.confirmButton")))])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }